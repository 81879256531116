import React from 'react';
import mixpanel from 'mixpanel-browser';
import Flex from '../Flex';

import {
  Modal,
  Overlay,
  Button,
  MacLogo,
  WindowsLogo,
  LinuxLogo,
} from './styled';

const DownloadModal = ({ onClose }) => (
  <Overlay onClick={onClose} className="modal-close-trigger">
    <Modal>
      <h2 style={{ textAlign: 'center' }}>Choose a platform</h2>
      <Flex row style={{ margin: '20px 0' }}>
        <a
          href="https://chronos-api.web-pal.com/release/dmg"
          onClick={() => mixpanel.track('Downloaded Timetracker - OS X')}
        >
          <MacLogo />
        </a>
        <a
          href="https://chronos-api.web-pal.com/release/exe"
          onClick={() => mixpanel.track('Downloaded Timetracker - Windows')}
        >
          <WindowsLogo />
        </a>
        <a
          href="https://chronos-api.web-pal.com/release/AppImage"
          onClick={() => mixpanel.track('Downloaded Timetracker - Linux')}
        >
          <LinuxLogo />
        </a>
      </Flex>
      <Button
        className="modal-close-trigger"
        onClick={onClose}
      >
        Close
      </Button>
    </Modal>
  </Overlay>
);

export default DownloadModal;
