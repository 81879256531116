import React from 'react'

import {
  StartScreenContainer,
  ProductsSectionContainer,
  FooterLinks,
  ChronosLogo,
  Link,
  Middledot,
  GrandHeading,
} from './styled';

import TimesheetsSection from './TimesheetsSection/TimesheetsSection';
import TimetrackerSection from './TimetrackerSection/TimetrackerSection';
import TrelloPowerupSection from './TrelloPowerupSection/TrelloPowerupSection';

const StartScreen = ({
  showModal,
  mode,
}) => (
  <StartScreenContainer>
    <ChronosLogo />
    <ProductsSectionContainer>
      <TimetrackerSection
        showModal={showModal}
        mode={mode}
      />
      {mode === 'trello' ? <TrelloPowerupSection /> : <TimesheetsSection />}
    </ProductsSectionContainer>
    {/* 
    <FooterLinks>
      <Link>How it works</Link>
      <Middledot />
      <Link>Pricing</Link>
      <Middledot />
      <Link>Support</Link>
    </FooterLinks>
    */}
  </StartScreenContainer>
)

export default StartScreen
