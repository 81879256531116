import styled from 'styled-components';
import Headroom from 'react-headroom';
import { Link as ScrollLink } from 'react-scroll';
import ChronosLogoSVG from '../../../../assets/chronos-logo.svg';


export const HeaderContainer = styled.header`
  height: 70px;
  // background-image: linear-gradient(-162deg, #335684 0%, #233D65 57%, #172B4D 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0,0,0,.25);
  width: 100vw;
  padding: 0 24px;
  background: white;
  box-shadow: 0 2px 10px rgba(0,0,0,.25);

  @media (max-width: 768px) {
    padding: 0 16px;
  }

  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 2;
`;

export const ChronosLogo = styled(ChronosLogoSVG)`
  height: 48px;
  width: auto;
`;
export const ChronosLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const ChronosTitle = styled.span`
  font-family: Circular-Bold;
  font-size: 1.1rem;
  color: #172B4D;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;
export const ActiveItem = styled.div`
  font-family: Circular-Book;
  font-size: 1.1rem;
  color: #172B4D;
  padding-bottom: 2px;
  margin-top: 4px;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
  ${props => props.item === 'timetracker' ? `
    border-bottom: 2px solid #0052CC;
  ` : `
    border-bottom: 2px solid #FFAB00;
  `}
`;
export const InactiveItem = styled(ScrollLink)`
  border-radius: 3px;
  padding: 6px 14px;
  font-family: Circular-Bold;
  font-size: 1.1rem;
  cursor: pointer;
  user-select: none;
  ${props => props.item === 'timetracker' ? `
    background: #FFAB00;
    color: #172B4D;
  ` : `
    background: #0052CC;
    color: white;
  `}
`;

export const Links = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Link = styled.a`
  font-family: Circular-Book;
  color: #0052CC;
  font-size: 14px;
  height: 100%;
  margin: 0 15px;
  cursor: pointer;
`;
