import styled from 'styled-components';
import MacLogoSVG from '../../../../../assets/mac-logo.svg';
import WindowsLogoSVG from '../../../../../assets/windows-logo.svg';
import LinuxLogoSVG from '../../../../../assets/linux-logo.svg';
import { Element } from 'react-scroll';

import { SubHeading as SubHeadingDefault } from '../../../../styles/typography';

export const SecondScreen = styled(Element)`
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 40px 0 20px 0;
  @media (max-width: 768px) {
    padding: 40px 20px 20px 20px;
  }
`;

export const SubHeading = styled(SubHeadingDefault)`
  @media (max-width: 768px) {
    padding-right: 5px;
  }
`

export const MacLogo = styled(MacLogoSVG)`
  height: 34px;
  margin-right: 24px;
  cursor: pointer;
  g {
   fill: #8993A3;
  }
  &:hover g {
    fill: #253858;
  }
`;

export const WindowsLogo = styled(WindowsLogoSVG)`
  height: 34px;
  margin-right: 24px;
  cursor: pointer;
  g {
   fill: #8993A3;
  }
  &:hover g {
    fill: #253858;
  }
`;

export const LinuxLogo = styled(LinuxLogoSVG)`
  height: 34px;
  cursor: pointer;
  g {
   fill: #8993A3;
  }
  &:hover g {
    fill: #253858;
  }
`;

export const Macbook = styled.img`
  width: 100%;
  margin-bottom: 0;
`;

export const MacbookContainer = styled.div`
  display: flex;
  flex-direction: column;
  // width: 80.5vw;
  // max-width: 1200px;
  position: relative;
  :@media (max-width: 768px) {
    min-height: 550px;
    width: 100%;
  }
`;

export const PlatformLogos = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 34px;
`;

export const ChronosScreenshot = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 3%;
  // max-width: 76%;
  max-height: 78%;
`;

export const WatchVideoLink = styled.a`
  font-family: Circular-Book;
  font-size: 16px;
  color: #0045C9;
  margin-right: 3px;
  :@media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export const WatchVideoTimer = styled.a`
  font-family: Circular-Book;
  font-size: 12px;
  color: #0045C9;
  :@media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
