import styled from 'styled-components';
import AtlassianWaveFooterSVG from '../../../../../assets/atlassian-wave-footer.svg';

export const FifthScreen = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 0 0;
  background: #FFFFFF;
  position: relative;
  @media (max-width: 768px) {
    height: auto;
    padding: 20px 16px 0 16px;
  }
`;

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px 10px 90px 10px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const TimetrackerCTAButton = styled.button`
  background: #0052CC;
  border-radius: 3px;
  font-family: Circular-Bold;
  font-size: 20px;
  color: #FFFFFF;
  padding: 20px;
  border: 0;
  width: fit-content;
  cursor: pointer;
  &:hover, :active {
    border-color: #004BBD;
    background: #004BBD;
    color: #FFF;
    box-shadow: 0 2px 4px rgba(0,0,0,.25);
  }
`;

export const TimesheetsCTAButton = styled.a`
  text-decoration: none;
  underline: none;
  background: #FFAB00;
  border-radius: 3px;
  font-family: Circular-Bold;
  align-self: center;
  font-size: 20px;
  color: #172B4D;
  padding: 20px;
  border: 0;
  width: fit-content;
  cursor: pointer;
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    margin-right: 0px;
  }
  &:hover, :active {
    text-decoration: none;
    underline: none;
    background: #FF991F;
    border-color: #FF991F;
    color: #172B4D;
  }
`;

