// TODO: replace link to be opened in a new tab;
import React from 'react'
import mixpanel from 'mixpanel-browser';

import Flex from '../../../../components/Flex';

import {
  TimetrackerSection,
  GithubLogo,
  GithubLogoMobile,
  Title,
  Description,
  DownloadButton,
  DownloadButtonMobile,
  JumpToSection,
  Heading,
  SubHeading,
  HeadingContainer,
} from './styled';

const StartScreen = ({
  showModal,
  mode,
}) => (
  <TimetrackerSection>
    <a
      href="https://github.com/web-pal/chronos-timetracker"
      target="_blank"
      onClick={() => mixpanel.track('Opened github link - Start screen')}
    >
      <GithubLogo />
    </a>
    <Flex column>
      <HeadingContainer>
        <Heading>Chronos Timetracker</Heading>
        <a
          href="https://github.com/web-pal/chronos-timetracker"
          target="_blank"
          onClick={() => mixpanel.track('Opened github link - Start screen')}
        >
          <GithubLogoMobile />
        </a>
      </HeadingContainer>
      <SubHeading>
        {
          mode === 'trello' ?  `Desktop client for Trello. ` : `Desktop client for JIRA. `
        }
        <br />
        {`Track time, upload automatic and `}
        <br />
        {`manual worklogs without a hassle. `}
        <br />
        {`Cross-platform. Open-sourced. Free.`}
      </SubHeading>
      <Flex row>
        <DownloadButton
          onClick={() => {
            showModal();
            mixpanel.track('Downloaded Timetracker Plugin - Start screen');
          }}
        >Download now</DownloadButton>
        <DownloadButtonMobile
          to="timetracker-section"
          smooth
          duration={500}
        >Learn more</DownloadButtonMobile>
      </Flex>
    </Flex>
    <JumpToSection to="timetracker-section" smooth duration={500}>
      Learn more
    </JumpToSection>
  </TimetrackerSection>
)

export default StartScreen
