import React from 'react'

import { GrandHeading } from '../../../styles/typography';

import {
  FifthScreen,
  CTAContainer,
  TimetrackerCTAButton,
  TimesheetsCTAButton,
} from './styled';

import Flex from '../../../components/Flex';

export default ({
  showModal,
  mode,
}) => (
  <FifthScreen>
    <GrandHeading>
      Get started now
    </GrandHeading>
    <CTAContainer>
      {
        mode === 'trello' ? (
          <TimesheetsCTAButton
            href="https://trello.com/power-ups/5d2dded97bdded0fdb6638f8"
            target="__blank"
          >
            Try Chronos Power-Up
          </TimesheetsCTAButton>
        ) : (
          <TimesheetsCTAButton
            href="https://marketplace.atlassian.com/plugins/jira-chronos/cloud/overview"
            target="__blank"
          >
            Try Chronos Stylesheets
          </TimesheetsCTAButton>
        )
      }
      <TimetrackerCTAButton
        onClick={() => {
          showModal();
        }}
      >
        Get Chronos Timetracker
      </TimetrackerCTAButton>
    </CTAContainer>
  </FifthScreen>
)
