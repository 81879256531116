import React from 'react'
import mixpanel from 'mixpanel-browser';

import { GrandHeading } from '../../../styles/typography';

import {
  ThirdScreen,
  ThirdScreenFooter,
  SubHeading,
  Divider,
  BulletPoint,
  BulletDescription,
  Link,
  BulletsContainer,
  Bullet,
  BulletDot,
  DownloadButton
} from './styled';

import Flex from '../../../components/Flex';

export default ({
  showModal,
  mode,
}) => (
  <ThirdScreen>
    <GrandHeading>
      Chronos Timetracker
    </GrandHeading>
    <SubHeading>
      {`Your working tool kit. Open a tracker, browse tasks, `}
      <br />
      {`pick what you want to work on and start tracking. `}
      <br />
      Move it to "Done" when you've finished. Without leaving an app.
    </SubHeading>
    <Divider />
    <BulletsContainer>
      <Bullet>
        <BulletPoint>
          <BulletDot />
          Secured and open-sourced
        </BulletPoint>
        <BulletDescription>
          {`You can be sure your data is secured. `}
          <br />
          {`Code of the project is in public access `}
          <br />
          and hosted on Github. 
        </BulletDescription>
      </Bullet>
      <Bullet>
        <BulletPoint>
          <BulletDot />
          Customizable worklogs
        </BulletPoint>
        <BulletDescription>
          {mode === 'trello'
            ? `Besides native worklogs information `
            : `Besides native JIRA worklogs information `
          }
          <br />
          {`we provide a lot of data like screenshots, `}
          <br />
          {`activities, types and comments. `}
          <br />
          You also can add worklogs manually.
        </BulletDescription>
      </Bullet>
      <Bullet>
        <BulletPoint>
          <BulletDot />
          Simple to manage your tasks
        </BulletPoint>
        <BulletDescription>
          {mode === 'trello'
            ? `Chronos is also minimalistic Trello `
            : `Chronos is also minimalistic JIRA `
          }
          <br />
          {`client, where you can perform most `}
          <br />
          {`needed actions like changing issue’s `}
          <br />
          status or checking it’s details.
        </BulletDescription>
      </Bullet>
    </BulletsContainer>
    <DownloadButton
      onClick={() => {
        showModal();
        mixpanel.track('Chronos Timetracker click - Last screen');
      }}
    >
      Download now
    </DownloadButton>
  <ThirdScreenFooter />
  </ThirdScreen>
)
