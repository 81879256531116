import styled from 'styled-components';
import GithubLogoSVG from '../../../../../../assets/github-logo.svg';
import { Link } from 'react-scroll';

import {
  Heading as HeadingDefault,
  SubHeading as SubHeadingDefault
} from '../../../../../styles/typography';

export const TimetrackerSection = styled.div`
  background-color: #FFAB00;
  width: 50%;
  height: 444px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 40px;
  align-items: flex-start;
  @media (max-width: 940px) {
    width: calc(100% - 32px);
    padding: 20px;
    justify-content: flex-start;
    height: auto;
    min-height: 200px;
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 940px) {
    margin-bottom: 14px;
  }
`;

export const Heading = styled(HeadingDefault)`
  @media (max-width: 940px) {
    margin-bottom: 0;
  }
`;

export const SubHeading = styled(SubHeadingDefault)`
  text-align: start;
  @media (max-width: 940px) {
    text-align: start;
  }
`;

export const GithubLogo = styled(GithubLogoSVG)`
  height: 18px;
  cursor: pointer;
  &:hover g {
    fill: black;
  }
  @media (max-width: 940px) {
    display: none;
  }
`;

export const GithubLogoMobile = styled(GithubLogoSVG)`
  height: 18px;
  cursor: pointer;
  &:hover g {
    fill: black;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

export const DownloadButton = styled.button`
  background: #0052CC;
  border-radius: 3px;
  font-family: Circular-Bold;
  font-size: 16px;
  color: #FFFFFF;
  padding: 10px 27px;
  border: 0;
  width: fit-content;
  cursor: pointer;
  &:hover, :active {
    border-color: #004BBD;
    background: #004BBD;
    color: #FFF;
    box-shadow: 0 2px 4px rgba(0,0,0,.25);
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const DownloadButtonMobile = styled(Link)`
  background: #0052CC;
  border-radius: 3px;
  font-family: Circular-Bold;
  font-size: 16px;
  color: #FFFFFF;
  padding: 10px 27px;
  border: 0;
  width: fit-content;
  cursor: pointer;
  &:hover, :active {
    border-color: #004BBD;
    background: #004BBD;
    color: #FFF;
    box-shadow: 0 2px 4px rgba(0,0,0,.25);
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

export const JumpToSection = styled(Link)`
  font-family: Circular-Book;
  font-size: 11px;
  color: #505F79;
  cursor: pointer;
  &:hover {
    color: #42526E;
    background: rgba(0, 0, 0, .03);
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 940px) and (min-width: 768px) {
    margin-top: 8px;
  }
`;
