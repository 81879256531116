import styled from 'styled-components';
import ChronosLogoSVG from '../../../../../assets/chronos-logo.svg';

import { GrandHeading as GrandHeadingDefault } from '../../../../styles/typography';

export const GrandHeading = styled(GrandHeadingDefault)`
  @media (min-width: 940px) {
    display: none;
  }
  font-size: 1.7rem;
  color: white;
  text-align: center;
  margin-bottom: 24px;
`;

export const StartScreenContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: #172B4D;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 940px) {
    justify-content: flex-start;
    height: auto;
    padding: 20px 0 40px 0;
  }
`;

export const ProductsSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 960px;
  height: 444px;
  margin-top: calc(50vh - 222px);
  @media (max-width: 940px) {
    flex-direction: column;
    width: 90%;
    align-items: center;
    height: auto;
    margin-top: 24px;
  }
`;

export const FooterLinks = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 940px) {
    display: none;
  }
`;

export const ChronosLogo = styled(ChronosLogoSVG)`
  position: absolute;
  width: 220px;
  height: 240px;
  top: calc(50vh - 367px);
  @media (max-width: 940px) {
    height: 124px;
    width: auto;
    position: relative;
    top: auto;
  }
`;

export const Link = styled.a`
  font-family: Circular-Book;
  font-size: 14px;
  color: rgba(225, 225, 225, .8);
  cursor: pointer;
  &:hover {
    color: white;
  }
`;

export const Middledot = styled.div`
  background: rgba(225, 225, 225, .8);
  border-radius: 100px;
  width: 4px;
  height: 4px;
  margin: 0 5px;
`;
