import styled from 'styled-components';
import AtlassianWaveFooterSVG from '../../../../../assets/atlassian-wave-footer.svg';
import { Element } from 'react-scroll';

import {
  SubHeading as SubHeadingDefault,
  Heading as HeadingDefault,
} from '../../../../styles/typography';

export const ForthScreenContainer = styled(Element)`
  width: 100vw;
  height: auto;
  background-color: #172B4D;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  @media (max-width: 768px) {
    justify-content: flex-start;
    height: auto;
    padding: 20px 16px 0 16px;
  }
`;

export const Heading = styled(HeadingDefault)`
  color: white;
  @media (max-width: 768px) {
    font-size: 1.4rem;
    text-align: center;
  }
`;

export const SubHeading = styled(SubHeadingDefault)`
  color: white;
  text-align: center;
  margin-bottom: 42px;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const Screenshot = styled.img`
  max-width: 60vw;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const MetaItem = styled.div`
  padding-left: 16px;
  border-left: 4px solid #FFAB00;
  @media (min-width: 768px) {
    ${props => props.hiddenOnLarge && 'display: none'};
  }
  @media (max-width: 768px) {
    ${props => props.hiddenOnMobile && 'display: none'};
    margin-bottom: 30px;
  }
`;

export const MetaItemTitle = styled.h3`
  font-family: Circular-Bold;
  color: white;
  font-size: 20px;
  margin-top: 8px;
`;

export const MetaItemDescription = styled.p`
  font-family: Circular-Book;
  color: white;
  font-size: 14px;
  @media (max-width: 768px) {
    br {
      display: none;
    }
  }
`;

export const HL = styled.span`
  color: #FFAB00;
`;

export const DownloadButton = styled.a`
  text-decoration: none;
  underline: none;
  background: #FFAB00;
  border-radius: 3px;
  font-family: Circular-Bold;
  align-self: center;
  font-size: 20px;
  color: #172B4D;
  padding: 20px;
  border: 0;
  width: fit-content;
  cursor: pointer;
  &:hover, :active {
    text-decoration: none;
    underline: none;
    background: #FF991F;
    border-color: #FF991F;
    color: #172B4D;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 90vw;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const ScreenFooter = styled(AtlassianWaveFooterSVG)`
  margin-top: 20px;
  width: 100vw;
  height: auto;
  g {
    fill: white;
  }
`;
