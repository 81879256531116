import React, { Component } from 'react'
import Headroom from 'react-headroom';
import { scrollSpy, Events } from 'react-scroll';

import Flex from '../Flex';

import {
  HeaderContainer,
  ChronosLogo,
  TimetrackerItem,
  TimesheetsItem,

  ActiveItem,
  InactiveItem,
  ChronosLogoContainer,
  ChronosTitle,

  Links,
  Link,
} from './styled';

class MobileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 'timetracker',
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    // Events.scrollEvent.register('begin', function(to, element) {
    //   console.log("begin", arguments);
    // });

    // Events.scrollEvent.register('end', function(to, element) {
    //   console.log("end", arguments);
    // });

    // scrollSpy.update();
  }

  onScroll = (e) => {
    const offsetTop = window.pageYOffset || document.documentElement.scrollTop;
    if (offsetTop > 2000 && this.state.activeItem === 'timetracker') {
      this.setState({ activeItem: 'timesheets' });
    }
    if (offsetTop < 2000 && this.state.activeItem === 'timesheets') {
      this.setState({ activeItem: 'timetracker' });
    }
  }

  toggleItem = (itemName) => () => {
    this.setState({ activeItem: itemName });
  }

  render() {
    const {activeItem} = this.state;
    const featuresScreenName = this.props.mode === 'trello' ? 'Power-Up' : 'Timesheets';
    // const isTimetracker = document.getElementById('timesheets-section') &&
    //   document.getElementById('timesheets-section').getBoundingClientRect() > 0;
    // console.log(isTimetracker);

    return (
      <Headroom pinStart={800}>
        <HeaderContainer>
          <ChronosLogoContainer>
            <ChronosLogo />
            <ChronosTitle>Chronos</ChronosTitle>
            <ActiveItem item={activeItem}>
              {activeItem === 'timetracker' ? 'Timetracker' : 'Timesheets'}
            </ActiveItem>
            {/*
            <Links>
              <Link>How it works</Link>
              <Link>Pricing</Link>
              <Link>Support</Link>
            </Links>
            */}
          </ChronosLogoContainer>
          <InactiveItem
            to={`${activeItem === 'timetracker' ? 'timesheets' : 'timetracker'}-section`}
            smooth
            duration={500}
            item={activeItem}
            onClick={() => {
              if (activeItem === 'timesheets') {
                this.toggleItem('timetracker')();
              } else {
                this.toggleItem('timesheets')();
              }
            }}
          >
            {activeItem === 'timetracker' ? featuresScreenName : 'Timetracker'}
          </InactiveItem>
        </HeaderContainer>
      </Headroom>
    );
  }
}

export default MobileHeader;
