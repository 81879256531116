import React from 'react'
import mixpanel from 'mixpanel-browser';

import Flex from '../../../../components/Flex';

import {
  TimesheetsSection,
  AtlassianMarketplaceLogo,
  Title,
  Description,
  DownloadButton,
  DownloadButtonMobile,
  JumpToSection,
  ProductContent,
  HeadingContainer,
} from './styled';

export default () => (
  <TimesheetsSection>
    <a
      href="https://marketplace.atlassian.com/plugins/jira-chronos/cloud/overview"
      target="__blank"
      onClick={() => mixpanel.track('Chronos Timesheets click - Start screen (Marketplace)')}
    >
      <AtlassianMarketplaceLogo  />
    </a>
    <ProductContent>
      <HeadingContainer>
        <Title>Chronos Timesheets</Title>
      </HeadingContainer>
      <Description>
        {`JIRA plugin to view worklogs. `}
        <br />
        {`Watch what your team is busy with, `}
        <br />
        {`calculate salaries, view screenshots `}
        <br />
        {`and activity levels. Rich and flexible.`}
      </Description>
      <Flex row>
        <a
          href="https://marketplace.atlassian.com/plugins/jira-chronos/cloud/overview"
          target="__blank"
          onClick={() => mixpanel.track('Chronos Timesheets click - Start screen')}
        >
          <DownloadButton>View on Marketplace</DownloadButton>
        </a>
        <DownloadButtonMobile
          to="timesheets-section"
          smooth
          duration={500}
        >
          Learn more
        </DownloadButtonMobile>
      </Flex>
    </ProductContent>
    <JumpToSection to="timesheets-section" smooth duration={500}>
      Learn more
    </JumpToSection>
  </TimesheetsSection>
)

