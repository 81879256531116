import styled from 'styled-components';
import MacLogoSVG from '../../../../assets/mac-logo.svg';
import WindowsLogoSVG from '../../../../assets/windows-logo.svg';
import LinuxLogoSVG from '../../../../assets/linux-logo.svg';

export const Overlay = styled.div`
  background: rgba(9, 30, 66, 0.54);
  bottom: 0;
  left: 0;
  opacity: 1;
  pointer-events: initial;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 220ms;
  z-index: 20;
  -webkit-transition: opacity 220ms;
  overflow: hidden;
`;

export const Modal = styled.div`
  background-color: #FFFFFF;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(9, 30, 66, 0.08), 0 2px 1px rgba(9, 30, 66, 0.08), 0 0 20px -6px rgba(9, 30, 66, 0.31);
  color: #091E42;
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 100%;
  outline: 0;
  width: 50vw;
  margin: 0 auto;
  margin-top: 120px;
  font-family: Circular-Book;
  padding: 20px;
  align-items: center;
  @media (max-width: 768px) {
    width: 80vw;
  }
`;

export const Button = styled.button`
  align-items: baseline;
  background: none;
  border-radius: 3px;
  border-width: 0;
  box-sizing: border-box;
  color: #505F79;
  display: inline-flex;
  font-size: inherit;
  font-style: normal;
  height: 2.2857142857142856em;
  line-height: 2.2857142857142856em;
  margin: 0;
  max-width: 100%;
  outline: none !important;
  padding: 0 8px;
  pointer-events: auto;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  cursor: pointer;
  align-self: flex-end;
  &:hover {
    background: rgba(9, 30, 66, 0.08);
  }
`;

export const MacLogo = styled(MacLogoSVG)`
  height: 64px;
  width: auto;
  margin-right: 24px;
  cursor: pointer;
  g {
   fill: #8993A3;
  }
  &:hover g {
    fill: #253858;
  }
`;

export const WindowsLogo = styled(WindowsLogoSVG)`
  height: 64px;
  width: auto;
  margin-right: 24px;
  cursor: pointer;
  g {
   fill: #8993A3;
  }
  &:hover g {
    fill: #253858;
  }
`;

export const LinuxLogo = styled(LinuxLogoSVG)`
  height: 64px;
  width: auto;
  cursor: pointer;
  g {
   fill: #8993A3;
  }
  &:hover {
    g {
      fill: #253858;
    }
  }
`;
