import React from 'react'
import mixpanel from 'mixpanel-browser';
import macbook from '../../../../assets/macbook.png';
import chronosScreenshot from '../../../../assets/chronos-screenshot.png';

import {
  SecondScreen,
  MacLogo,
  WindowsLogo,
  LinuxLogo,
  ChronosScreenshot,
  Macbook,
  WatchVideoLink,
  WatchVideoTimer,
  PlatformLogos,
  MacbookContainer,
  SubHeading,
} from './styled';

import {
  Heading,
} from '../../../styles/typography';

import Flex from '../../../components/Flex';

export default () => (
  <SecondScreen name="timetracker-section" id="timetracker-section">
    <Heading>Easy timetracking</Heading>
    <SubHeading>
      Large and informative for managers. Easy to use for developers.
    </SubHeading>
    <PlatformLogos>
      <a
        href="https://chronos-api.web-pal.com/release/dmg"
        onClick={() => mixpanel.track('Downloaded Timetracker - OS X')}
      >
        <MacLogo />
      </a>
      <a
        href="https://chronos-api.web-pal.com/release/exe"
        onClick={() => mixpanel.track('Downloaded Timetracker - Windows')}
      >
        <WindowsLogo />
      </a>
      <a
        href="https://chronos-api.web-pal.com/release/AppImage"
        onClick={() => mixpanel.track('Downloaded Timetracker - Linux')}
      >
        <LinuxLogo />
      </a>
    </PlatformLogos>
    <MacbookContainer>
      <ChronosScreenshot src={chronosScreenshot} />
      <Macbook src={macbook} />
    </MacbookContainer>
  </SecondScreen>
)
