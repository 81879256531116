import React from 'react'
import mixpanel from 'mixpanel-browser';

import Flex from '../../../../components/Flex';

import {
  TimesheetsSection,
  TrelloLogo,
  Title,
  Description,
  DownloadButton,
  DownloadButtonMobile,
  JumpToSection,
  ProductContent,
  HeadingContainer,
} from './styled';

export default () => (
  <TimesheetsSection>
    <a
      href="https://marketplace.atlassian.com/plugins/jira-chronos/cloud/overview"
      target="__blank"
      onClick={() => mixpanel.track('Chronos Timesheets click - Start screen (Marketplace)')}
    >
      <TrelloLogo  />
    </a>
    <ProductContent>
      <HeadingContainer>
        <Title>Chronos Power-Up for Trello</Title>
      </HeadingContainer>
      <Description>
        {`Chronos is a Power-Up that brings work logs to Trello cards.`}
        <br />
        {`It allows to track time, set estimate and get a report. `}
        {`Rich and flexible.`}
      </Description>
      <Flex row>
        <a
          href="https://trello.com/power-ups/5d2dded97bdded0fdb6638f8"
          target="__blank"
          onClick={() => mixpanel.track('Chronos Timesheets click - Start screen')}
        >
          <DownloadButton>View on Marketplace</DownloadButton>
        </a>
        <DownloadButtonMobile
          to="timesheets-section"
          smooth
          duration={500}
        >
          Learn more
        </DownloadButtonMobile>
      </Flex>
    </ProductContent>
    <JumpToSection to="timesheets-section" smooth duration={500}>
      Learn more
    </JumpToSection>
  </TimesheetsSection>
)

