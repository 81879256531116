import styled from 'styled-components';
import TrelloLogoSvg from '../../../../../../assets/trello-logo.svg';
import { Link } from 'react-scroll';

import { Heading, SubHeading } from '../../../../../styles/typography';

export const TimesheetsSection = styled.div`
  background-color: #0052CC;
  width: 50%;
  height: 444px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 40px;
  align-items: flex-end;
  @media (max-width: 940px) {
    width: calc(100% - 32px);
    padding: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    min-height: 200px;
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: 940px) {
    margin-bottom: 14px;
  justify-content: flex-start;
  }
`;

export const TrelloLogo = styled(TrelloLogoSvg)`
  height: 18px;
  @media (max-width: 940px) {
    display: none;
  }
`;

export const ProductContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 940px) {
    align-items: flex-start;
  }
`;

export const Title = styled(Heading)`
  color: white;
`;

export const Description = styled(SubHeading)`
  color: white;
  text-align: end;
  @media (max-width: 940px) {
    text-align: start;
  }
`;

export const DownloadButton = styled.button`
  background: #FFAB00;
  border-radius: 3px;
  /* View on Marketplace: */
  font-family: Circular-Bold;
  font-size: 16px;
  color: #172B4D;
  padding: 10px 27px;
  border: 0;
  width: fit-content;
  cursor: pointer;
  &:hover, :active {
    background: #FF991F;
    border-color: #FF991F;
    color: #172B4D;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const DownloadButtonMobile = styled(Link)`
  background: #FFAB00;
  border-radius: 3px;
  /* View on Marketplace: */
  font-family: Circular-Bold;
  font-size: 16px;
  color: #172B4D;
  padding: 10px 27px;
  border: 0;
  width: fit-content;
  cursor: pointer;
  &:hover, :active {
    background: #FF991F;
    border-color: #FF991F;
    color: #172B4D;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

export const JumpToSection = styled(Link)`
  font-family: Circular-Book;
  font-size: 11px;
  color: rgba(255,255,255,0.70);
  cursor: pointer;
  &:hover {
    color: white;
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 940px) and (min-width: 768px) {
    margin-top: 8px;
  }
`;
