import React from 'react'
import mixpanel from 'mixpanel-browser';
import calendarView from '../../../../assets/chronos-timesheets/calendar-view.png';
import timelineView from '../../../../assets/chronos-timesheets/timeline-view.png';

import EyeSvg from '../../../../assets/icons/eye.svg';
import ReportsSvg from '../../../../assets/icons/reports.svg';
import InvoiceSvg from '../../../../assets/icons/invoice.svg';
import SearchSvg from '../../../../assets/icons/search.svg';

import Flex from '../../../components/Flex';

import {
  ForthScreenContainer,
  Heading,
  SubHeading,
  Content,

  Screenshot,
  MetaItem,
  MetaItemTitle,
  MetaItemDescription,
  HL,
  DownloadButton,
  ScreenFooter,
} from './styled';

const metaItems = [
  {
    icon: <EyeSvg width="28" />,
    title: 'Two views available',
    description: (
      <MetaItemDescription>
        Observe worklogs in <HL>Calendar</HL>{` and`}
        <HL>Timeline</HL>{` views.`}
        <br />
        Pick the one that’s more comfortable to you.
      </MetaItemDescription>
    )
  },
  {
    icon: <SearchSvg width="26" />,
    title: 'Easy to find what you need',
    description: (
      <MetaItemDescription>
        {`Rich and simple filters. Check live what your team is busy with `}
        <br />
        or how many hours last sprint took. <HL>It’s configurable </HL>
        <br />
        and flexible. See only data you need.
      </MetaItemDescription>
    )
  },
  {
    icon: <ReportsSvg width="26" />,
    title: 'Full-packed reports',
    description: (
      <MetaItemDescription>
        {`Our reports provide a lot of useful information such as `}
        <br />
        <HL> activity levels</HL>{`, screenshots, idle perioids,worklog types `}
        <br />
        and comments. In a <HL>nice and convinient</HL> way. 
      </MetaItemDescription>
    )
  },
  {
    icon: <InvoiceSvg width="22" />,
    title: 'Invoice in one click',
    description: (
      <MetaItemDescription>
        You can set <HL>salaries</HL>{` for each of your employes and include `}
        <br />
        them in your report. <HL>Export</HL> your reports to printable CSV.
      </MetaItemDescription>
    )
  },
]

const JiraFeatures = () => (
  <ForthScreenContainer id="timesheets-section" name="timesheets-section">

    <Heading>Get best solution for worklog reports</Heading>
    <SubHeading>
      {`Chronos Stylesheets is a JIRA plugin that shows you `}
      <br />
      worklogs in a nice and convenient way. 
    </SubHeading>

    <Content>

      <Flex
        column
        justifyContent="space-between"
        style={{ paddingBottom: 44 }}
      >
        {metaItems.map((i, key) =>
          <MetaItem
            hiddenOnMobile={i.title === 'Two views available'}
            key={key}
          >
            {i.icon}
            <MetaItemTitle>{i.title}</MetaItemTitle>
            {i.description}
          </MetaItem>
        )}
        <DownloadButton
          href="https://marketplace.atlassian.com/plugins/jira-chronos/cloud/overview"
          target="__blank"
          onClick={() => mixpanel.track('Chronos Timesheets click - Timesheets screen')}
        >Try Chronos Stylesheets</DownloadButton>
      </Flex>

      <Flex column justifyContent="space-between">
        <MetaItem hiddenOnLarge style={{ marginBottom: 14 }}>
          {metaItems[0].icon}
          <MetaItemTitle>{metaItems[0].title}</MetaItemTitle>
          {metaItems[0].description}
        </MetaItem>
        <Screenshot src={calendarView} alt="Calendar view" />
        <Screenshot src={timelineView} alt="Timeline view" />
      </Flex>

    </Content>
    <ScreenFooter />

  </ForthScreenContainer>
)

export default JiraFeatures
