import React, { Component } from 'react'
import mixpanel from 'mixpanel-browser';

import StartScreen from '../components/Screens/StartScreen/StartScreen';
import SecondScreen from '../components/Screens/SecondScreen/SecondScreen';
import ThirdScreen from '../components/Screens/ThirdScreen/ThirdScreen';
import JiraFeatures from '../components/Screens/JiraFeatures/JiraFeatures';
import TrelloFeatures from '../components/Screens/TrelloFeatures/TrelloFeatures';
import FifthScreen from '../components/Screens/FifthScreen/FifthScreen';

import Layout from '../components/Layout/Layout';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

import DownloadModal from '../components/DownloadModal/DownloadModal';

const mode = 'trello'

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = { showDownloadModal: false };
  }

  showModal = () => this.setState({ showDownloadModal: true });
  hideModal = (e) => {
    if (typeof e.target.className === 'string' &&
      e.target.className.indexOf('modal-close-trigger'
    ) > -1) {
      this.setState({ showDownloadModal: false })
    }
  };

  componentDidMount() {
    mixpanel.init('5aba46554a0e8baceca1eee3947bd1ac');
  }

  render() {
    return (
      <Layout>
        {this.state.showDownloadModal &&
          <DownloadModal onClose={this.hideModal} />
        }
        <Header mode={mode} />
        <StartScreen showModal={this.showModal} mode={mode} />
        <SecondScreen />
        <ThirdScreen showModal={this.showModal} mode={mode} />
        {
          mode === 'trello'
            ? <TrelloFeatures />
            : <JiraFeatures />
        }
        <FifthScreen showModal={this.showModal} mode={mode} />
        <Footer showModal={this.showModal} mode={mode} />
      </Layout>
    );
  }
}

export default IndexPage;
