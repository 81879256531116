import React from 'react'

import trelloCardButtonGif from '../../../../assets/track_time_using_powerup_v3.gif';
import trelloManualGif from '../../../../assets/add_worklog_manually_v2.gif';
import trelloCardBadgeGif from '../../../../assets/card_badges_status.png';

import worklogsReportScreenshot from '../../../../assets/worklogs_reports_calendar.png';

import ClockSvg from '../../../../assets/icons/clock.svg';
import ManuaclockSvg from '../../../../assets/icons/manual-clock.svg';
import EyeSvg from '../../../../assets/icons/eye.svg';
import ReportsSvg from '../../../../assets/icons/reports.svg';

import {
  ForthScreenContainer,
  Heading,
  SubHeading,
  Content,

  Screenshot,
  MetaItemTitle,
  MetaItemDescription,
  HL,
  DownloadButton,
  ScreenFooter,
} from './styled';

const features = [
  {
    icon: (
      <div>
        <ClockSvg
          width="28"
          height="28"
          style={{
            fill: 'white',
          }}
        />
      </div>
    ),
    title: 'Time tracking',
    description: (
      <MetaItemDescription>
        For every card, Chronos provides an additional action button - "Start timer",
        which you can use to track time for the card.
      </MetaItemDescription>
    ),
    screenshot: (
      <Screenshot src={trelloCardButtonGif} alt="Calendar view" />
    ),
  },
  {
    icon: (
      <div>
        <ManuaclockSvg
          width="28"
          height="28"
          style={{
            fill: 'white',
          }}
        />
      </div>
    ),
    title: 'Manual work log',
    description: (
      <MetaItemDescription>
        {`Add work log manually. Rich and simple. `}
      </MetaItemDescription>
    ),
    screenshot: (
      <Screenshot src={trelloManualGif} alt="Calendar view" />
    ),
  },
  {
    icon: <EyeSvg width="26" />,
    title: 'Live tracking status update',
    description: (
      <MetaItemDescription>
        {`Watch who is working on card live right on the board.`}
      </MetaItemDescription>
    ),
    screenshot: (
      <Screenshot src={trelloCardBadgeGif} alt="Calendar view" />
    ),
  },
  {
    icon: <ReportsSvg width="28" />,
    title: 'Reports',
    description: (
      <MetaItemDescription>
        Observe worklogs in <HL>Calendar</HL>{` and`}
        <HL>Timeline</HL>{` views.`}
        <br />
        Pick the one that’s more comfortable to you.
      </MetaItemDescription>
    ),
    screenshot: (
      <Screenshot src={worklogsReportScreenshot} alt="Calendar view" />
    ),
  },
]

const ForthScreen = () => (
  <ForthScreenContainer id="timesheets-section" name="timesheets-section">

    <Heading>Get best solution for time tracking in Trello</Heading>
    <SubHeading>
      {`Chronos PowerUp is a Trello plugin that brings `}
      <br />
      time tracking in a nice and convenient way. 
    </SubHeading>

    <Content>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}

      >
        {features.map((feature, key) =>
          <div
            key={key}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '40%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '16px',
                  borderLeft: '4px solid #FFAB00',
                }}
              >
                {feature.icon}
                <MetaItemTitle>
                  {feature.title}
                </MetaItemTitle>
                {feature.description}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              {feature.screenshot}
            </div>
          </div>
        )}
      </div>
      {/*
      <Flex
        column
        justifyContent="space-between"
        style={{ paddingBottom: 44 }}
      >
        {metaItems.map((i, key) =>
          <MetaItem
            hiddenOnMobile={i.title === 'Time tracking'}
            key={key}
          >
            {i.icon}
            <MetaItemTitle>{i.title}</MetaItemTitle>
            {i.description}
          </MetaItem>
        )}
        <DownloadButton
          href="https://trello.com/power-ups/5d2dded97bdded0fdb6638f8"
          target="__blank"
        >
          Try Chronos Power-Up
        </DownloadButton>
      </Flex>

      <Flex column justifyContent="space-between">
        <MetaItem hiddenOnLarge style={{ marginBottom: 14 }}>
          {metaItems[0].icon}
          <MetaItemTitle>{metaItems[0].title}</MetaItemTitle>
          {metaItems[0].description}
        </MetaItem>
        <Screenshot src={timelineView} alt="Timeline view" />
        <Screenshot src={timelineView2} alt="Timeline view" />
        <Screenshot src={timelineView3} alt="Timeline view" />
      </Flex>
      */}

    </Content>
    <ScreenFooter />

  </ForthScreenContainer>
)

export default ForthScreen
